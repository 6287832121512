import React, { useState, useEffect } from "react"
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import Breadcrumb from "src/components/Breadcrumb"
import Tags from "src/components/images/Tags";
import Footer from "src/components/Footer";

import { Container, Col, Row } from "react-bootstrap"


const ImageDetail = (data ) => {
    const pageData = data.pageContext
    const API_URL = process.env.GATSBY_API_URL;

    let title = pageData.title;
    let content = pageData.descriptionText;
    let uploadedAt = new Date(pageData.createdAt).toLocaleString('en-US', {
        month: 'long',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    });
    let imageUrl = `${API_URL}image/fuck/${pageData.hash}.jpg`;
    let tags = pageData.tags;
    let aspectRatio = pageData.width / pageData.height;

    const defaultWidth = 800; 


    const [viewportSize, setViewportSize] = useState({
        width: typeof window !== 'undefined' ? window.innerWidth : defaultWidth,
        height: typeof window !== 'undefined' ? window.innerHeight : defaultWidth / aspectRatio,
    });

    useEffect(() => {
        const handleResize = () => {
            setViewportSize({
            width: window.innerWidth,
            height: window.innerHeight - 88, // Consider the header and padding
            })
        }

        window.addEventListener('resize', handleResize)

        handleResize()

        return () => window.removeEventListener('resize', handleResize)

    }, [])

    // Calculate whether the image is in portrait or landscape orientation
    const isPortrait = aspectRatio < 1;

    // The maximum height available for the image
    const maxHeight = viewportSize.height - 88; // Subtracting space for header and padding

    // The maximum width available for the image
    const maxWidth = viewportSize.width;

    // Calculate the size of the image based on its orientation and aspect ratio
    let imageSize = {};

    if (isPortrait) {
        // For portrait images, set the height to the maximum or less, based on the aspect ratio
        const calculatedHeight = Math.min(maxHeight, maxWidth / aspectRatio);
        imageSize = {
            height: `${calculatedHeight}px`,
            width: `${calculatedHeight * aspectRatio}px`, // Calculate width based on the height and aspect ratio
        };
    } else {
        // For landscape images, set the width to the maximum or less, based on the aspect ratio
        const calculatedWidth = Math.min(maxWidth, maxHeight * aspectRatio);
        imageSize = {
            width: `${calculatedWidth}px`,
            height: `${calculatedWidth / aspectRatio}px`, // Calculate height based on the width and aspect ratio
        };
    }

    const metadataTitle = `${title}`
    const metadataDescription = `${content}`
    const metadataImg = imageUrl

    return (
        <Layout page="image">
          <SEO title={metadataTitle}
                 description={metadataDescription} 
                 imageURL={metadataImg} />
          <Container style={{ paddingBottom: "20px" }}> {/* Include header height + padding and space at the bottom */}
              <>
                <Row>
                  <Col xs={12} style={{ height: `calc(100vh - 88px)`, display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <img 
                        src={imageUrl} 
                        alt="Image description" // Consider making this dynamic or more descriptive
                        style={imageSize}
                      />
                  </Col>
                </Row>
                <Breadcrumb type={`image`} />
                <Row>
                  <Col xs={12}>
                    <h1>{title}</h1>
                    <div dangerouslySetInnerHTML={{__html: content}} />
                    <div>Uploaded at: {uploadedAt}</div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Tags tags={tags} />
                  </Col>
                </Row>
              </>
          </Container>
          <Footer />
        </Layout>
      );
      
}

export default ImageDetail